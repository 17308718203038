<template>
  <main class="h_p100" :class="active" id="panel2" ref="panel2">
    <header>{{ showData.mainTit }}</header>
    <h2>{{ showData.mainTitEn }}</h2>
    <h1 class="mainWidth">{{ showData.subTit }}</h1>
    <p class="mainWidth">
      {{ showData.desc1 }}
    </p>
    <p class="mainWidth">{{ showData.desc2 }}</p>
    <p class="mainWidth" v-if="showData.desc3">{{ showData.desc3 }}</p>
    <ul class="navList mainWidth">
      <li
        class="navItem flex-between cp"
        :class="active === item.key ? 'active' : ''"
        v-for="item in navList"
        :key="item.key"
        @click="active = item.key"
      >
        <span>{{ item.label }}</span>
        <img :src="require('../../assets/' + item.key + '.png')" alt="" srcset="" />
      </li>
    </ul>
  </main>
</template>

<script>
export default {
  name: 'workSlider',
  data() {
    return {
      active: 'tec',
      showData: {},
      navList: [
        { label: '技术咨询', key: 'tec' },
        { label: '研发服务', key: 'dev' },
        { label: '产品销售', key: 'pro' }
      ]
    }
  },
  watch: {
    active: {
      immediate: true,
      handler(val) {
        switch (val) {
          case 'tec':
            this.showData = {
              mainTit: '主要业务',
              mainTitEn: 'Main Business',
              subTit: '为金融机构、政企平台提供专业化定制开发咨询',
              desc1:
                '公司成立以来已相继为证券业、信托业、金融科技业等相关公司提供多项金融产品定制开发咨询服务。主要涉及资管数字化、财富管理、行情交易、',
              desc2: 'FOF投研、股权激励管理、资讯等领域，部分平台交易与管理规模已达百亿级规模。'
            }
            break
          case 'dev':
            this.showData = {
              mainTit: '研发业务',
              mainTitEn: 'Development Business',
              subTit: '倾力投研，打造核心竞争力',
              desc1:
                '2021年以来公司在苏州上海两地分别设立了研发中心，研发中心主要由一批来自全球知名咨询公司、证券业、银行业、保险业、信托业等有志之士组',
              desc2:
                '建而成。紧跟市场行业动向、市场政策格局、外部技术变革、自有资源等多源因素，开展各产品线实施与业务拓展。主要涉及投资研究、财富管理、',
              desc3: '资产管理、行情交易等领域。'
            }
            break
          case 'pro':
            this.showData = {
              mainTit: '销售业务',
              mainTitEn: 'Sales Business',
              subTit: '助力金融企业加速迈进资管-财富管理大时代',
              desc1:
                '公司依托于股东背景，陆续研发了股权激励系统、一站式财富管理平台、行情交易系统、资产管理综合金融平台、运维等金融业系统。产品销售业务',
              desc2:
                '主要分为对上述系统的标准化销售与部署，以及基于客户需求，以上述系统为基础进行差异化与定制化开发。助力企业以最少生产要素投入实现效',
              desc3: '率、管理、业绩的多方位提升。'
            }
            break
        }
      }
    }
  },
  mounted() {
    // console.log(this.$refs)
    // this.loadImage(require('../../assets/workSpaceBg2.png'))
    // this.loadImage('../../assets/workSpaceBg3.png')
    //   .then((res) => {
    //     console.log(res)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
main {
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  &.tec {
    background-image: url('../../assets/workSpaceBg1.png');
  }
  &.dev {
    background-image: url('../../assets/workSpaceBg2.png');
  }
  &.pro {
    background-image: url('../../assets/workSpaceBg3.png');
  }
  header {
    margin-top: 130px;
    height: 42px;
    font-size: 42px;
    font-family: Alibaba PuHuiTi;
    font-weight: 800;
    color: #ffffff;
    line-height: 42px;
    position: relative;
    &::after {
      content: '';
      height: 4px;
      width: 76px;
      background: #0278f2;
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  h2 {
    margin-top: 50px;
    font-size: 28px;
    font-weight: 600;
    color: #b2b2b2;
    font-family: Alibaba PuHuiTi;
  }
  h1 {
    text-align: left;
    margin: 0 auto;
    margin-top: 90px;
    font-size: 32px;
    font-family: Alibaba PuHuiTi;
    font-weight: 800;
    color: #ffffff;
    margin-bottom: 60px;
  }
  p {
    text-align: left;
    margin: 0 auto;
    margin-top: 25px;
    width: 1450px;
    font-size: 22px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #ffffff;
  }
  .mainWidth {
    max-width: 1462px;
  }
  ul.navList {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    border-top: 1px solid #838588;
    border-bottom: 1px solid #838588;
    border-right: 1px solid #ffffff;
    .navItem {
      border-left: 1px solid #838588;
      flex: 1;
      height: 164px;
      line-height: 164px;
      text-align: left;
      padding: 0 32px 0 55px;
      &.active {
        background: rgba(0, 94, 168, 0.5);
      }
      span {
        font-size: 36px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #ffffff;
      }
      img {
        height: 66px;
      }
    }
  }
}
</style>