<template>
  <main class="h_p100" id="panel1" ref="panel1">
    <div class="h_p100" id="item">
      <div class="swiper h_p100">
        <swiper ref="mySwiper2" :options="swiperOptions" class="h_p100">
          <swiper-slide>
            <slider-1></slider-1>
          </swiper-slide>
          <swiper-slide>
            <slider-2></slider-2>
          </swiper-slide>
          <swiper-slide>
            <slider-3></slider-3>
          </swiper-slide>
          <div class="swiper2 swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </main>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import slider1 from './slider1.vue'
import slider2 from './slider2.vue'
import slider3 from './slider3.vue'
// import * as swiperAni from '../../config/animate'
export default {
  name: 'bannerSlider',
  components: {
    swiper,
    swiperSlide,
    slider1,
    slider2,
    slider3
  },
  data() {
    return {
      swiperOptions: {
        autoplay: true,
        loop: true,
        lazyLoading: true,
        pagination: {
          el: '.swiper2', //与slot="pagination"处 class 一致
          clickable: true //轮播按钮支持点击
        },
        on: {
          init: function () {
            //   console.log(that)
            //   that.$swiperAnimateCache(this) //隐藏动画元素
            //   that.$swiperAnimate(this) //初始化完成开始动画
            // },
            // slideChange: function () {
            //   that.$swiperAnimate(this) //每个slide切换结束时也运行当前slide动画
            //   //that.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名
          }
        }
      }
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
#item {
  ::v-deep .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 36px;
  }
  ::v-deep .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin-left: 0;
    margin-right: 18px;
    &:last-child {
      margin-right: 0;
    }
  }
  ::v-deep .swiper-pagination-bullet {
    border-radius: 3px;
    width: 22px;
    height: 6px;
    opacity: 1;
    background: #cecece;
    &.swiper-pagination-bullet-active {
      background: #0278f2;
    }
  }
}
</style>