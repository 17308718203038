<template>
  <main class="blackBg" id="panel5">
    <!-- main -->
    <div class="warp">
      <ul class="ulTitle">
        <li class="first flex-center">
          <span class="left imgOneSmall"></span>
          <span class="leftWrit">友情链接</span>
        </li>
        <li class="second flex-center">
          <span class="left imgTwoSmall"></span>
          <span class="leftWrit">联系我们</span>
        </li>
      </ul>
      <ul class="ulSecond">
        <li class="third">
          <a class="companytitle" href="https://www.idwzx.com/" target="_blank"
            >东吴金科</a
          >
          <span class="companyPlace"
            >地址：中国(江苏)自由贸易试验区苏州片区苏州工业园区星阳街5号2201室</span
          >
        </li>
      </ul>
      <ul class="ulThird">
        <li class="tell">电话：021-60107366</li>
      </ul>
      <ul class="ulFifth">
        <li class="email">邮箱：service@dzsinfotech.com</li>
      </ul>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="one">
        <div class="left">
            <img src="../../assets/cmmidev.png" alt="" srcset="" height="100%" @click="openCMI" title="点击查看">
            <img src="../../assets/certImg.png" class="certImg" alt="" srcset="" height="100%" title="点击查看" @click="openPdf">
        </div>
        <div class="left"></div>
        <div class="right">
          <img
            src="https://www.idwzx.com/wp-content/themes/idwzx/images/footer_outicon_01.jpg"
            alt=""
          />
          <div class="rightTwo"></div>
        </div>
      </div>
      <p>
        Copyright 东之晟（苏州）信息科技有限公司 |
        <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2021007997号-2 </a>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32059002004542">
          <img src="https://www.idwzx.com/wp-content/themes/idwzx/images/gaicon.png" style="vertical-align: top;" alt="">
            苏公网安备 32059002004542号</a>
      </p>
    </div>
  </main>
</template>

<script>
export default {
  name: "productSlider",
  methods: {
    openPdf () {
      console.log(process.env);
      window.open(process.env.BASE_URL + '/ISO27001 东之晟.pdf')
    },
    openCMI () {
      window.open(process.env.BASE_URL + '/cmmi.png')
    }
  }
};
</script>

<style lang="scss" scoped>
.blackBg {
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 100%;
  background-color: #0b0d16;
  .warp {
    margin: 100px 0;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0 230px;
      &.ulTitle {
        height: 120px;
        line-height: 120px;
        border-bottom: rgba(207, 208, 213, 0.15) 1px solid;
      }
      li {
        list-style: none;
      }
      .first,
      .second {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .left {
          width: 20px;
          height: 20px;
          background-size: 100% 100%;
        }
        .imgOneSmall {
          background: url("@/assets/footLink.png") center center no-repeat;
          background-size: 100% 100%;
        }
        .imgTwoSmall {
          background: url("@/assets/footTell.png") center center no-repeat;
          background-size: 100% 100%;
        }
        .leftWrit {
          font-size: 18px;
          color: #cccccc;
          margin-left: 13px;
        }
      }
      .second {
        margin-left: 159px;
      }
    }
    .ulSecond {
      margin-top: 95px;
      .companytitle,
      .companyPlace {
        color: #cccccc;
        font-size: 14px;
      }
      .companytitle {
        margin-left: 43px;
      }
      .companyPlace {
        margin-left: 165px;
      }
      .third {
        a {
          &:hover {
            color: dodgerblue;
            text-decoration: underline;
          }
        }
      }
    }
    .ulThird,
    .ulFifth {
      margin-top: 77px;
      margin-left: 265px;
      li {
        color: #cccccc;
        font-size: 14px;
      }
    }
  }
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #cfd0d5;
  padding: 41px 231px 49px 228px;
  a {
    &:hover {
      color: dodgerblue;
      text-decoration: underline;
    }
  }
  .one {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .left {
      height: 96px;
      img {
        margin-right: 15px;
        cursor: pointer;
      }
    }
    .right {
      .fightone {
        width: 75px;
        height: 50px;
        background: url("https://www.idwzx.com/wp-content/themes/idwzx/images/footer_outicon_01.jpg")
          center center no-repeat;
        background-size: 100% 100%;
        display: inline-block;
      }
      img {
        height: 96px;
      }
      .rightTwo {
        margin-left: 71px;
        width: 220px;
        height: 96px;
        background: url("@/assets/footQy.png") center center no-repeat;
        background-size: 100% 100%;
        display: inline-block;
      }
    }
  }
  p {
    margin-top: 47px;
    color: #cacaca;
    font-size: 14px;
    text-align: left;
  }
}
</style>