import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const state = {
  headerActiveTab: 1
}
const mutations = {
  set_headerActive_key (state, key) {
    state.headerActiveTab = key
  }
}
const getters = {
  active: state => state.headerActiveTab
}
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions: {
  },
})
