<template>
  <div id="app" class="h_p100">
    <app-header />
    <Home />
  </div>
</template>

<script>
import Home from './views/Home.vue'
import appHeader from './views/header.vue'
export default {
  name: 'App',
  components: {
    Home,
    appHeader
  }
}
</script>

<style>
#app {
  font-family: Alibaba PuHuiTi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}
</style>
