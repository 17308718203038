import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
]
const createRouter = () =>
  new Router({
    mode: 'history', // require service support
    // mode: 'hash',
    base: __dirname,
    scrollBehavior: () => ({ y: 0 }),
    routes
  })
const router = createRouter()
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export { resetRouter }
export default router
