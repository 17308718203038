<template>
  <!-- 背景 -->
  <main class="companyBg" id="panel4">
    <!-- main -->
    <div class="warp">
      <h1 class="hfrist">"</h1>
      <h2>公司简介</h2>
      <p>
        东之晟（苏州）信息科技有限公司（以下简称“东之晟”）于2020年11月16日发起成立。其管理团队、核心技术骨干及业务人员均来自于全球知名咨询公司、证券业、银行业、保险业、信托业。东之晟基于其卓越的金融科技能力与丰富的投资管理经验，明确以“金融科技”为核心，“服务好资管-财富管理大时代”为主要宗旨，围绕着“资管”、“财富管理”、“投研”、“行情”、“交易”、“资讯”等领域开展产品咨询、产品研发、产品定制开发、项目合作等业务。截至目前公司已经取得高新技术企业认证、软件企业评估 、科技型中小企业认证、CMMI3软件成熟度认证及近20项自主研发的专利与知识产权。
      </p>
      <h1 class="hsecond">"</h1>
    </div>
  </main>
</template>

<script>
export default {
  name: 'companyDesc'
}
</script>

<style lang="scss" scoped>
.companyBg {
  width: 100%;
  height: 100%;
  background: url('@/assets/company.png') center center no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  // position: fixed;
  .warp {
    margin: 200px 269px 0 256px;
    h1 {
      font-size: 80px;
      color: #fff;
    }
    .hfrist {
      text-align: left;
    }
    h2 {
      text-align: left;
      font-size: 42px;
      font-family: Alibaba PuHuiTi;
      font-weight: 800;
      color: #ffffff;
      margin-top: 10px;
    }
    .hsecond {
      text-align: right;
      margin-top: 70px;
    }
    p {
      margin-top: 50px;
      font-size: 22px;
      color: #fff;
      text-align: left;
      line-height: 58px;
      font-weight: 400;
    }
  }
}
</style>