<template>
  <main class="item h_p100 flex-column flex-center">
    <h1 class="mb-20 animate fadeInUp">科技赋能业务</h1>
    <h1 class="animate fadeInUp">实现业务与管理体系全域连接、能力复用</h1>
  </main>
</template>

<script>
export default {
  name: 'slider-2'
}
</script>

<style lang="scss" scoped>
.item {
  background: url('./images/banner2.png') no-repeat;
  background-size: 100% 100%;
  h1 {
    font-size: 80px;
    color: #fff;
    &:first-child {
      // margin-top: 420px;
    }
  }
}
</style>>
