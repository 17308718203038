<template>
  <main id="panel3" class="h_p100">
    <header>产品简介</header>
    <h2>Product introduction</h2>
    <ul class="flex-between panelList">
      <li class="cp" v-for="item in panelList" :key="item.key">
        <img :src="item.imgSrc" alt="" class="display" width="100%" />
        <main>
          <h3>{{ item.title }}</h3>
          <p>{{ item.desc }}</p>
        </main>
      </li>
    </ul>
  </main>
</template>

<script>
export default {
  name: 'productSlider',
  data() {
    return {
      panelList: [
        {
          imgSrc: require('@/assets/pro_1.png'),
          key: 'pro_1',
          title: '一站式财富管理平台',
          desc: '包含行情、资讯、交易三大模块。其中资讯包括市场个股资讯、时政资讯、热点资讯、行业资讯等；行情模块提供自选股列表、个股行情分时图和K线图，市场行情展示和分析相关功能：具体包括指数行情、板块行情、个股榜单、市场涨跌个股异动、专题分析等功能；交易模块提供普通股票、国债逆回购、场内基金、期权、两融、理财交易等功能。分别从资讯、行情、交易全方位赋能金融企业C端与B端业务拓展。'
        },
        {
          imgSrc: require('@/assets/pro_2.png'),
          key: 'pro_2',
          title: '股权激励系统',
          desc: '涵盖员工管理、角色管理、激励计划管理、激励分析、费用核算、绩效考核、股票回购、短信管理、企业列表等模块。股权激励系统主要分上市与非上市两大业务场景，适用于所有有意向开展股权激励的公司，为企业提供多元化融资管理工具。其次该系统从业务场景上实现一站式管理，企业股权激励发起、后端维护、员工前端行权等。并附有行权监控、行权条件监控、员工绩效管理等功能，助力企业实现以股权激励为业务场景的全链条管理。'
        },
        {
          imgSrc: require('@/assets/pro_3.png'),
          key: 'pro_3',
          title: '科创新金融平台',
          desc: '科创新金融平台涵盖科创资讯管理、工作日志管理、客户管理、科创项目管理、律所管理模块。同时前端平台提供各类渠道融资服务商信息与沙龙会议，支持各各领域创业者自主在平台寻求融资服务，并在线上实时对接服务机构，为创业者提供融资便利。对于资方可在该平台寻找大量有潜力创业项目，精准把我商机。同时平台辅以各类融资立项法律服务，最终实现科创项目立项、创建、稳健运行的全生命周期管理。'
        },
        {
          imgSrc: require('@/assets/pro_4.png'),
          key: 'pro_4',
          title: '业务运行支撑系统',
          desc: '可实现对各系统前后端的功能迭代、线上运维、监控告警等一系列自动化运维部署操作，具体包括应用发布模块、脚本发布模块、资源组维护模块、自动化发布、分支管理、自动化测试模块。满足线上系统7*24小时的稳定运行，及版本持续集成和交付，助力企业降低开发与运营成本。'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  background: url('../../assets/productIntroductionBg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  header {
    margin-top: 130px;
    height: 42px;
    font-size: 42px;
    font-family: Alibaba PuHuiTi;
    font-weight: 800;
    color: #333333;
    line-height: 42px;
    position: relative;
    &::after {
      content: '';
      height: 4px;
      width: 76px;
      background: #0278f2;
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  h2 {
    margin-top: 50px;
    font-size: 28px;
    font-weight: 600;
    color: #b2b2b2;
    font-family: Alibaba PuHuiTi;
  }
  .panelList {
    padding: 0 208px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    li {
      width: 340px;
      height: 612px;
      background: #ffffff;
      box-shadow: 0px 9px 41px 10px rgba(174, 174, 174, 0.2);
      transition: all 0.3s;
      main {
        padding: 30px 24px;
        h3 {
          text-align: left;
          font-size: 22px;
          font-family: Alibaba PuHuiTi;
          font-weight: bold;
          color: #333333;
          margin-bottom: 20px;
        }
        p {
          text-align: left;
          font-size: 14px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #686868;
          line-height: 28px;
        }
      }
      &:hover {
        transform: translateY(-50px);
        opacity: 1;
        h3,
        p {
          color: #0082ea;
        }
      }
    }
  }
}
</style>