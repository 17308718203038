<template>
  <!-- <div class="swiper-container h_p100"> -->
  <!-- <div class="swiper-wrapper"> -->
  <!-- <banner-slider></banner-slider> -->
  <!-- <product-slider></product-slider> -->
  <!-- <work-slider></work-slider> -->
  <!-- </div> -->
  <!-- 如果需要分页器 -->
  <!-- <div class="swiper-pagination"></div> -->

  <!-- 如果需要导航按钮 -->
  <!-- <div class="swiper-button-prev"></div> -->
  <!-- <div class="swiper-button-next"></div> -->

  <!-- 如果需要滚动条 -->
  <!-- <div class="swiper-scrollbar"></div> -->
  <!-- </div> -->
  <div class="home h_p100">
    <banner-slider></banner-slider>
    <work-slider></work-slider>
    <product-slider></product-slider>
    <company-introduction></company-introduction>
    <footer-vue></footer-vue>
  </div>
</template>
<script>
// import 'swiper/dist/js/swiper'
// import 'swiper/dist/css/swiper.css'
// import Swiper from 'swiper'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
import bannerSlider from './banner'
import productSlider from './productIntroduction'
import workSlider from './workSpace'
import companyIntroduction from './companyIntroduction'
import footerVue from './footer'
export default {
  name: 'homeVue',
  components: {
    // swiper,
    // swiperSlide,
    bannerSlider,
    productSlider,
    workSlider,
    companyIntroduction,
    footerVue
  },
  data() {
    return {
      // swiperOptions: {
      //   mousewheel: true,
      //   direction: 'vertical', // 垂直切换选项
      //   effect: 'fade',
      //   pagination: {
      //     type: 'bullets',
      //     el: '.swiper1', //与slot="pagination"处 class 一致
      //     clickable: true //轮播按钮支持点击
      //   },
      //   on: {
      //     transitionStart: function () {
      //       store.commit('set_headerActive_key', this.activeIndex + 1)
      //     }
      //   }
      // }
    }
  },
  watch: {
    // '$store.getters.active': {
    //   handler(val) {
    //     if (val === 2) {
    //       this.loadImage(require('../assets/workSpaceBg2.png'))
    //       this.loadImage(require('../assets/workSpaceBg3.png'))
    //     }
    //     this.$refs.mySwiper.swiper.slideTo(val - 1, 800, true) //切换到第一个slide，速度为1秒
    //   }
    // }
  },
  mounted() {
    let that = this
    window.onscroll = function () {
      // console.log(document.querySelector('#panel2').offsetTop, this.pageYOffset)
      if (document.querySelector('#panel2').offsetTop - this.pageYOffset < 180) {
        that.loadImage(require('../assets/workSpaceBg2.png'))
        that.loadImage(require('../assets/workSpaceBg3.png'))
      }
      // console.log(this.offsetTop)
      // console.log(document.querySelector('#panel2').offsetTop)
    }
  },
  methods: {
    loadImage(src) {
      let p = new Promise(function (resolve, reject) {
        let img = new Image()
        img.src = src
        console.log(img)
        img.onload = function () {
          //加载时执行resolve函数
          resolve(img)
        }
        img.onerror = function () {
          reject(src)
        }
      })
      return p
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  ::v-deep .swiper-container-vertical > .swiper-pagination-bullets {
    right: 65px;
  }
  ::v-deep .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin-top: 0;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ::v-deep .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.5);
    &.swiper-pagination-bullet-active {
      background: #fff;
    }
  }
}
</style>>