<template>
  <header class="flex-between">
    <img src="../assets/logo.png" alt="" class="logo" />
    <ul class="navList">
      <li
        class="fl cp"
        v-for="item in navList"
        :key="item.key"
        :class="active === item.key ? 'active' : ''"
        @click="changeNav(item.key)"
      >
        <a :href="'#panel' + item.key">{{ item.name }}</a>
      </li>
    </ul>
  </header>
</template>

<script>
export default {
  name: 'appHeader',
  data() {
    return {
      navList: [
        { name: '首页', key: 1 },
        { name: '主营业务', key: 2 },
        { name: '产品简介', key: 3 },
        { name: '公司简介', key: 4 },
        { name: '联系我们', key: 5 }
      ]
    }
  },
  computed: {
    active: {
      get() {
        return this.$store.getters.active
      },
      set(key) {
        this.$store.commit('set_headerActive_key', key)
      }
    }
  },
  methods: {
    changeNav(key) {
      this.active = key
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 30px 230px;
  z-index: 9999;
  img {
    height: 50px;
  }
  ul.navList {
    li {
      font-size: 18px;
      color: rgba(255, 255, 255, 0.5);
      margin-right: 108px;
      &.active {
        color: #fff;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>