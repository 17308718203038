<template>
  <main class="item h_p100 flex-column flex-center swiper-lazy" data-background="./images/banner3.png">
    <h1 class="mb-20">科技引领时代</h1>
    <h1>加速信息技术应用创新、构建自主护城河</h1>
  </main>
</template>

<script>
export default {
  name: 'slider-1'
}
</script>

<style lang="scss" scoped>
.item {
  background: url('./images/banner3.png') no-repeat;
  background-size: 100% 100%;
  h1 {
    font-size: 80px;
    color: #fff;
    &:first-child {
      // margin-top: 420px;
    }
  }
}
</style>>
