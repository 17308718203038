<template>
  <main
    class="item h_p100 flex-column flex-center ani"
    swiper-animate-effect="fadeInUp"
    swiper-animate-duration="0.5s"
    swiper-animate-delay="0s"
  >
    <h1 class="mb-20">科技改变金融</h1>
    <h1 class="">助力企业加速迈进资管-财富管理大时代</h1>
  </main>
</template>

<script>
export default {
  name: 'slider-1'
}
</script>

<style lang="scss" scoped>
.item {
  background: url('./images/banner1.png') no-repeat;
  background-size: 100% 100%;
  h1 {
    font-size: 80px;
    color: #fff;
    &:first-child {
      // margin-top: 420px;
    }
  }
}
</style>>
